<template>
  <v-container>
    <v-btn text color="accent" @click="$router.go(-1)" class="mb-2">
      <v-icon left> mdi-arrow-left-thick </v-icon>
      Back
    </v-btn>
    <h1 class="mb-4">Fund Wallets</h1>
    <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p>

    <v-stepper v-model="stepperModel" vertical>
      <v-stepper-step :complete="stepperModel > 1" step="1">
        Fund Wallet
        <small> Enter the amount you want to fund your wallet with </small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <div class="mb-5">
          <div class="max-w-2xl">
            <p
              v-if="!user.kycverify"
              class="text-red-500 bg-red-100 p-3 rounded text-sm"
            >
              You need to be a KYC certified user before you can use these
              option. Go to your
              <router-link
                to="/user/settings?tabs=5"
                class="underline text-current text-red-500"
                >profile verification</router-link
              >
              to get verified
            </p>
            <v-text-field
              label="Amount"
              placeholder="Amount to fund wallet"
              v-model="amount"
              outlined
              type="number"
              :disabled="!user.kycverify"
            ></v-text-field>
          </div>
        </div>
        <v-btn
          color="primary"
          :disabled="!user.kycverify"
          @click="initiateWalletFunding"
          :loading="isInitating"
        >
          Proceed
        </v-btn>
        <v-btn text @click="router.go(-1)"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 2" step="2">
        Confirm Funding Details
      </v-stepper-step>

      <v-stepper-content step="2">
        <div>
          <p>Verify and confirm your funding details</p>
          <v-card color="grey lighten-4 pa-3 mb-3" elevation="0" width="500px">
            <p class="mb-0">Full Name: {{ user.lname }} {{ user.fname }}</p>
            <p class="mb-0">Amount: NGN {{ numberWithCommas(amount) }}</p>
            <p class="mb-2">
              Total Payable: NGN {{ numberWithCommas(amountPayable) }}
            </p>
            <p class="red--text lighten-3 text-left mb-0">
              <small>
                Payment must come from your verified name. Any other name will
                be rejected
              </small>
            </p>
          </v-card>
          <div class="mb-5">
            <p class="mb-1">PLEASE AVOID THE FOLLOWING WHILE MAKING PAYMENT</p>
            <v-card color="primary lighten-1 pa-3 white--text">
              <ol>
                <li>
                  Don't add anything related to crypto in your bank transfer
                  remark / memo / purpose space.
                </li>
                <li>
                  Adding Bitcoin, BTC, ETH, USDT or any crypto remark can get
                  your account BLOCKED by CBN. We are generating a code for you
                  to make payment, only use the code generated and nothing else
                </li>
                <li>
                  Your Payment name must be the same as your verified name on
                  SnappyExchange. Any unknown/unverifed name will be refunded
                  with a penalty
                </li>
              </ol>
              <div class="mt-2 white--text flex items-center justify-start">
                <v-checkbox
                  class="text-red-500"
                  v-model="agreedToCondition"
                  color="red"
                  hide-details
                ></v-checkbox>
                <div
                  class="text-red-300 red--text red-text"
                  style="margin-top: 16px"
                >
                  You agree with our caution notice
                </div>
              </div>
            </v-card>
          </div>
          <div class="mb-3">
            Before you proceed, make sure you read our
            <a href="/terms-and-condition">Terms & Conditions</a>
          </div>
        </div>
        <v-btn
          color="primary"
          @click="handleNextAfterTerms"
          :disabled="!agreedToCondition"
          :loading="isLoadingForTerms"
        >
          Proceed
        </v-btn>
        <v-btn text @click="stepperModel = 2"> Back </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 3" step="3">
        Success
        <small> Get order ID </small>
      </v-stepper-step>

      <v-stepper-content step="3">
        <div>
          <h4>Success</h4>
          <p>
            Your order was successfully submitted your invoice no is
            <b>{{ invoiceNumber }}</b>
          </p>
        </div>
        <v-btn color="primary" @click="stepperModel = 4"> Proceed </v-btn>
        <v-btn text> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 4" step="4">
        Instruction and Terms
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="4">
        <div class="mb-5">
          Do you understand that the use of cryptocurrency-related words or
          abbreviations like bitcoin or btc, eth, usdt should never be used in
          your transfer notes for naira payments to our bank?
        </div>
        <v-btn color="primary" @click="stepperModel = 5"> Yes </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 5" step="5">
        Instruction and Terms
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="5">
        <div class="mb-5">
          I understand that my wallet will not be credited if I used another
          person's bank account to pay?
        </div>
        <v-btn color="primary" @click="stepperModel = 6"> Yes </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 6" step="6">
        Instruction and Terms
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="6">
        <div class="mb-5">
          I understand that I will pay directly from my own bank account that
          bears the name that I registered with SnappyExchange?
        </div>
        <v-btn color="primary" @click="stepperModel = 7"> Yes </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 7" step="7">
        Instruction and Terms
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="7">
        <div class="mb-5">
          I understand that my invoice number is <b>{{ invoiceNumber }}</b> and
          I will use it as narration or depositor name when making payment via
          bank app or bank deposit to my snappyExchange wallet
        </div>
        <v-btn color="primary" @click="stepperModel = 8"> Yes </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 8" step="8">
        Instruction and Terms
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="8">
        <div class="mb-5">
          I understand my SnappyExchange wallet will not be credited if I don't
          use my invoice number which is <b>{{ invoiceNumber }}</b> as narration
          when making payment via bank app or depositors name when making
          payment via bank deposit
        </div>
        <v-btn color="primary" @click="stepperModel = 9"> Yes </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="stepperModel > 9" step="10">
        Confirm Funding Details
        <small> Read the Instruction </small>
      </v-stepper-step>

      <v-stepper-content step="9">
        <div class="mb-5">
          <div>
            <p>
              <b>Please answer below questions</b>
            </p>
            <div>
              <v-text-field
                label="What is your invoice number?"
                placeholder="Type your invoice number below"
                v-model="invoiceNumberTyped"
                outlined
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                label="What are you going to use as narration when making payment via bank app or bank deposit?"
                placeholder="Type your invoice number below"
                v-model="narration"
                outlined
              ></v-text-field>
            </div>
            <div>
              <div>
                <p>Please find below your order details</p>
                <v-card class="p-5 lighten-2 mb-5" color="grey" elevation="0">
                  <div>
                    <span>Invoice Number: </span>
                    <span>
                      <b>{{ invoiceNumber }}</b>
                    </span>
                  </div>
                  <div>
                    <span>Amount: </span>
                    <span>
                      <b>NGN {{ numberWithCommas(amountPayable) }} </b>
                    </span>
                  </div>
                </v-card>
              </div>
              <div>
                <p>
                  Please, transfer the required amount ({{
                    numberWithCommas(amountPayable)
                  }}
                  NGN). Make sure you include your invoice number
                  {{ invoiceNumber }} on the transaction narration/memo.
                </p>
              </div>
              <div>
                <p>
                  <b>Guidelines to Avoid Delay in Processing</b>
                </p>
                <div>
                  <ol>
                    <li>
                      Include {{ invoiceNumber }} as narration/remarks/memo on
                      the internet banking,Mobile banking or Bank transfer
                    </li>
                    <li>
                      Do not deposit below the generated amount which is
                      {{ numberWithCommas(amountPayable) }} NGN
                    </li>
                  </ol>
                  <p class="mt-3">
                    If you pay below the generated amount, your order will be
                    seen as an attempted fraud and it will be rejected. Manual
                    refund fee for an attempted fraud is 5,000 NGN. This will be
                    debited from your balance.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <v-btn
                text
                color="primary"
                @click="showAccountDetails"
                :disabled="
                  narration !== invoiceNumber ||
                  invoiceNumberTyped !== invoiceNumber
                "
              >
                CLICK HERE TO SEE OUR BANK ACCOUNT</v-btn
              >
            </div>
          </div>
        </div>
        <v-btn
          color="primary"
          @click="finishProcess"
          :disabled="
            narration !== invoiceNumber ||
            invoiceNumberTyped !== invoiceNumber ||
            clickedOnSee
          "
        >
          Done
        </v-btn>
        <v-btn text> Cancel </v-btn>
      </v-stepper-content>
    </v-stepper>

    <v-dialog v-model="isPaymentDialog" width="400">
      <v-card class="p-5" elevation="0">
        <h4>Bank Details to Make Payment</h4>
        <div class="mt-5">
          <v-card class="p-5 lighten-2 mb-5" color="grey" elevation="0">
            <div>
              <span>Bank: </span>
              <span>
                <b>{{ siteData.walletFundingBankName }}</b>
              </span>
            </div>
            <div>
              <span>Account No: </span>
              <span>
                <b>{{ siteData.walletFundingAccountNumber }}</b>
              </span>
            </div>
            <div>
              <span>Account Name: </span>
              <span>
                <b>{{ siteData.walletFundingAccountName }}</b>
              </span>
            </div>
          </v-card>
        </div>
        <p>
          <b>Note:</b> Once we receive your payment, your SnappyExchange wallet
          will be credited
        </p>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { numberWithCommas } from '../../../utils/helpers'
import wallet from '../../../api/users/wallet'

export default {
  data() {
    return {
      numberWithCommas,
      stepperModel: 1,
      isPaymentDialog: false,
      amount: '',
      isInitating: false,
      invoiceNumber: '',
      amountPayable: '',
      agreedToCondition: false,
      invoiceNumberTyped: '',
      narration: '',
      fundingId: '',
      isLoadingForTerms: false,
      clickedOnSee: false
    }
  },
  computed: {
    ...mapState({
      wallet: (state) => state.user.wallet,
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  created() {},
  methods: {
    async initiateWalletFunding() {
      if (!this.amount) return
      this.isInitating = true

      const res = await wallet.data().initiateWalletFunding(this.amount)
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
        this.isInitating = false
        return
      }
      this.invoiceNumber = res.data.data.reference
      this.amountPayable = res.data.data.amount
      this.fundingId = res.data.data._id
      this.isInitating = false
      this.stepperModel = 2
    },
    finishProcess() {
      this.$store.dispatch('alert', {
        message: 'Your wallet will be credited once we receive your payment',
        error: false,
        status: true
      })
      this.$router.push('/user/wallet')
    },
    async handleNextAfterTerms() {
      this.isLoadingForTerms = true
      const res = await wallet
        .data()
        .verifyWalletFunding({ id: this.fundingId })
      if (res.error) {
        this.$store.dispatch('alert', {
          message: res.errorMessage.message || res.internalError.message,
          error: true,
          status: true
        })
      }
      this.stepperModel = 3
      this.isLoadingForTerms = false
    },
    showAccountDetails() {
      if (this.invoiceNumberTyped !== this.invoiceNumber) {
        this.$store.dispatch('alert', {
          message: 'Invoice number does not match',
          error: true,
          status: true
        })
        return
      }

      this.isPaymentDialog = true
    },
    copy(copy) {
      const input = document.createElement('input')
      input.value = copy

      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      this.$store.dispatch('alert', { message: 'Copied!', time: 2000 })
    }
  }
}
</script>

<style scoped>
.bordered-account {
  background: #f9fafc;
  border: 2px dashed #bdbdbd;

  box-sizing: border-box;
  border-radius: 3px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  padding: 5px 0;
}

/* style all the .v-stepper__content that has .v-stepper__step--inactive as the next sibling */
.v-stepper__content + .v-stepper__step--inactive {
  opacity: 0;
}

/* .v-stepper__step--inactive {
  opacity: 0;
} */

.red-text {
  color: red;
}
</style>
